import React, { JSX } from 'react';
import useSWR from 'swr';
import {
  Box,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingOverlay } from '../../../common/components/loading-overlay/LoadingOverlay';
import { AccountDto, AccountsDto } from '../../../common/dtos/accounts';
import { FullCenter } from '../../../common/components/full-center/FullCenter';

const getAccounts = (url: string): Promise<AccountsDto> =>
  fetch(url).then((res) => res.json());

function NoAccounts(): JSX.Element {
  const navigate = useNavigate();

  return (
    <Box>
      <p>You have no accounts yet.</p>
      <div style={{ textAlign: 'center', marginTop: '26px' }}>
        <Button variant="outlined" onClick={() => navigate('/accounts/create')}>
          Create Account
        </Button>
      </div>
    </Box>
  );
}

function AccountSection({ accounts }: { accounts: AccountDto[] }): JSX.Element {
  return (
    <List sx={{ width: '100%' }}>
      {accounts.map((account) => (
        <ListItem key={account.id} disablePadding>
          <ListItemButton
            component="a"
            href={`/accounts/${account.id}/transactions`}
          >
            <ListItemText primary={account.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

export function AccountSelect(): JSX.Element {
  const { data, isLoading } = useSWR('/api/v1/accounts', getAccounts);

  return (
    <FullCenter>
      <LoadingOverlay status={isLoading ? 'loading' : 'ready'}>
        {data && (
          <Card>
            <CardContent sx={{ width: '300px' }}>
              {data.accounts.length === 0 ? (
                <NoAccounts />
              ) : (
                <AccountSection accounts={data.accounts} />
              )}
            </CardContent>
          </Card>
        )}
      </LoadingOverlay>
    </FullCenter>
  );
}
