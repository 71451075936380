import React, { JSX, useContext, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Snackbar,
} from '@mui/material';
import useSWRMutation from 'swr/mutation';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../account-context';
import {
  LoadingOverlay,
  OverlayStatus,
} from '../../../../common/components/loading-overlay/LoadingOverlay';
import { CreatedAccountInviteDto } from '../../../../common/dtos/accounts';

async function createInvitation(url: string): Promise<CreatedAccountInviteDto> {
  const res = await fetch(url, {
    method: 'POST',
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('There was an issue while creating an invite');
}

export function AccountInfo(): JSX.Element {
  const account = useContext(AccountContext);
  const navigate = useNavigate();
  const [status, setStatus] = useState<OverlayStatus>('ready');
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [invite, setInvite] = useState<CreatedAccountInviteDto>();
  const shareData = {
    url: window.location.origin,
  };
  const { trigger } = useSWRMutation(
    `/api/v1/accounts/${account.id}/invitations`,
    createInvitation,
    {
      onSuccess: (data) => {
        setStatus('ready');
        setSnackBarMessage('Invitation created');
        setInvite(data);
      },
      onError: () => {
        setStatus('ready');
        setSnackBarMessage('Something went wrong, try again');
      },
    }
  );

  const triggerCreateInvitation = () => {
    setStatus('loading');
    trigger();
  };

  const onShareClick = async ({ id, token }: CreatedAccountInviteDto) => {
    const inviteUrl = `${shareData.url}/api/v1/accounts/${account.id}/invitations/${id}/accept?token=${token}`;
    if (navigator.share) {
      await navigator.share({
        title: `Join ${account.name}`,
        text: `You where invited to join ${account.name} on Arnak.io`,
        url: inviteUrl,
      });
    } else {
      await navigator.clipboard.writeText(inviteUrl);
      setSnackBarMessage('Link copied to clipboard');
    }
  };

  return (
    <Card>
      <CardHeader title={account.name} subheader={account.id} />
      <CardContent>
        <LoadingOverlay status={status}>
          <Box className="ar-flex-column-center" sx={{ minHeight: '100px' }}>
            {!invite && (
              <Button variant="contained" onClick={triggerCreateInvitation}>
                Create Invite
              </Button>
            )}
            {invite && (
              <Button variant="contained" onClick={() => onShareClick(invite)}>
                Share
              </Button>
            )}
          </Box>
        </LoadingOverlay>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          onClick={() => navigate(`/accounts/${account.id}/transactions`)}
        >
          Cancel
        </Button>
      </CardActions>

      <Snackbar
        open={snackBarMessage !== ''}
        autoHideDuration={3000}
        onClose={() => setSnackBarMessage('')}
        message={snackBarMessage}
      />
    </Card>
  );
}
