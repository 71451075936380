import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Login } from './pages/auth/Login';
import { TransactionsPage } from './pages/account/account-context/transaction/overview/TransactionsPage';
import { AccountRoot } from './pages/account/AccountRoot';
import { AccountSelect } from './pages/account/account-select/AccountSelect';

import { Register } from './pages/auth/Register';
import { CreateAccount } from './pages/account/create-account/CreateAccount';
import { AccountInfo } from './pages/account/account-context/account-info/AccountInfo';
import { AccountContextRoot } from './pages/account/account-context/AccountContextRoot';
import { CreateTransaction } from './pages/account/account-context/transaction/CreateTransaction';
import { SubscriptionsPage } from './pages/account/account-context/subscription/SubscriptionsPage';
import { ManageSubscription } from './pages/account/account-context/subscription/ManageSubscription';

dayjs.extend(utc);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: 'auth/login',
    element: <Login />,
  },
  {
    path: 'auth/register',
    element: <Register />,
  },
  {
    path: 'accounts/*',
    element: <AccountRoot />,
    children: [
      {
        path: 'create',
        element: <CreateAccount />,
      },
      {
        path: 'select',
        element: <AccountSelect />,
      },
      {
        path: ':accountId/*',
        element: <AccountContextRoot />,
        children: [
          {
            path: 'info',
            element: <AccountInfo />,
          },
          {
            path: 'transactions',
            element: <TransactionsPage />,
          },
          {
            path: 'createTransaction',
            element: <CreateTransaction />,
          },
          {
            path: 'subscriptions',
            element: <SubscriptionsPage />,
          },
          {
            path: 'createSubscription',
            element: <ManageSubscription />,
          },
        ],
      },
    ],
  },
  {
    path: '/*',
    loader: () => redirect('/auth/login'),
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
