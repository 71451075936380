export class HttpError extends Error {
  readonly status: number;

  constructor(
    public readonly response: Response,
    public readonly body: unknown
  ) {
    super(response.statusText);
    this.status = response.status;
  }
}
export const restFetch = async (...args: Parameters<typeof fetch>) => {
  const resp = await fetch(...args);
  if (resp.ok) {
    return resp.json();
  }

  throw new HttpError(resp, await resp.json());
};

export function capitalizeFirstLetter(str: string): string {
  if (!str) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}
