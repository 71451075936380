import React, { JSX } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Period, SubscriptionDto } from '../../../../common/dtos/subscriptions';
import { capitalizeFirstLetter } from '../../../../common/utils/fetch';

interface SubscriptionsListProps {
  subscriptions: SubscriptionDto[];
}

function getDayWithSuffix(day: number): string {
  if (day > 3 && day < 21) return `${day}th`;
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
}

function formatTransactionDay(period: Period, transactionDay: number): string {
  switch (period) {
    case Period.monthly:
      return `Every month on the ${getDayWithSuffix(transactionDay)}`;
    default:
      return 'Every day';
  }
}

export function SubscriptionsList({
  subscriptions,
}: SubscriptionsListProps): JSX.Element {
  return (
    <Card sx={{ backgroundColor: 'white' }}>
      <CardContent sx={{ padding: 0, paddingBottom: '0 !important' }}>
        <List sx={{ padding: 0 }}>
          {subscriptions.map((subscription) => (
            <ListItemButton key={subscription.id}>
              <ListItemIcon>
                <ListItemAvatar>
                  <Avatar>
                    {subscription.expense_or_income === 'expense' ? (
                      <RemoveCircleOutlineIcon />
                    ) : (
                      <AddCircleOutlineIcon />
                    )}
                  </Avatar>
                </ListItemAvatar>
              </ListItemIcon>
              <ListItemText
                primary={subscription.title}
                secondary={`${capitalizeFirstLetter(subscription.expense_or_income)} of ${subscription.amount}, ${formatTransactionDay(subscription.period, subscription.transaction_day)}`}
              />
            </ListItemButton>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
