import React, { JSX, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Snackbar,
  TextField,
} from '@mui/material';
import useSWRMutation from 'swr/mutation';
import { useNavigate } from 'react-router-dom';
import {
  LoadingOverlay,
  OverlayStatus,
} from '../../../common/components/loading-overlay/LoadingOverlay';
import { CreatedDto } from '../../../common/dtos/common';
import { CreateAccountDto } from '../../../common/dtos/accounts';

async function createAccount(url: string, { arg }: { arg: CreateAccountDto }) {
  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(arg),
  });

  if (res.ok) {
    return res.json();
  }

  console.error('Error creating account', res);
  throw new Error('Error creating account');
}

export function CreateAccount(): JSX.Element {
  const navigate = useNavigate();
  const [status, setStatus] = useState<OverlayStatus>('ready');
  const [accountName, setAccountName] = useState('');
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const { trigger } = useSWRMutation('/api/v1/accounts', createAccount, {
    onSuccess: ({ id }: CreatedDto) => {
      setSnackBarMessage('Account created');
      navigate(`/accounts/${id}/transactions`);
    },
    onError: () => {
      setSnackBarMessage('Something went wrong, try again');
      setStatus('ready');
    },
  });

  function triggerAccountCreation() {
    setStatus('saving');
    trigger({
      name: accountName,
    } as CreateAccountDto);
  }

  return (
    <Box className="ar-flex-column-center" sx={{ flex: 1 }}>
      <LoadingOverlay status={status}>
        <Card>
          <CardContent sx={{ width: '300px' }}>
            <FormControl margin="normal">
              <TextField
                label="Account Name"
                type="text"
                size="small"
                value={accountName}
                onChange={({ target }) => setAccountName(target.value)}
              />
            </FormControl>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={triggerAccountCreation}
            >
              Create Account
            </Button>
          </CardActions>
        </Card>
      </LoadingOverlay>

      <Snackbar
        open={snackBarMessage !== ''}
        autoHideDuration={3000}
        onClose={() => setSnackBarMessage('')}
        message={snackBarMessage}
      />
    </Box>
  );
}
