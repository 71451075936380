import { ExpenseOrIncome } from './common';

export const Period = {
  monthly: 'monthly',
} as const;

export type Period = (typeof Period)[keyof typeof Period];

export interface SubscriptionDto {
  id: string;
  title: string;
  description?: string;
  period: Period;
  transaction_day: number;
  expense_or_income: ExpenseOrIncome;
  amount: number;
  start_date: number;
  end_date?: number;
  created_at: number;
  updated_at: number;
}

export interface SubscriptionsDto {
  subscriptions: SubscriptionDto[];
}

export interface CreateSubscriptionDto {
  title: string;
  period: Period;
  transaction_day: number;
  expense_or_income: ExpenseOrIncome;
  amount: number;
  description?: string;
  start_date: number;
  end_date?: number;
}
