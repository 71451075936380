import React, { JSX, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import {
  LoadingOverlay,
  OverlayStatus,
} from '../../../../common/components/loading-overlay/LoadingOverlay';
import { restFetch } from '../../../../common/utils/fetch';
import {
  CreateSubscriptionDto,
  Period,
} from '../../../../common/dtos/subscriptions';
import { ExpenseOrIncome } from '../../../../common/dtos/common';
import { PeriodSelector } from '../transaction/overview/period-selector/PeriodSelector';

function manageSubscription(
  url: string,
  { arg }: { arg: CreateSubscriptionDto }
) {
  return restFetch(url, {
    method: 'POST',
    body: JSON.stringify(arg),
  });
}

export function ManageSubscription(): JSX.Element {
  const navigate = useNavigate();
  const { accountId } = useParams<{ accountId: string }>();
  const [status, setStatus] = useState<OverlayStatus>('ready');
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [formValid, setFormValid] = useState(false);

  const [title, setTitle] = useState('');
  const [amount, setAmount] = useState('');
  const [expenseOrIncome, setExpenseOrIncome] =
    useState<ExpenseOrIncome>('expense');
  const [period, setPeriod] = useState<Period>(Period.monthly);
  const [transactionDay, setTransactionDay] = useState(15);
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState('');
  const [description, setDescription] = useState('');

  const { trigger } = useSWRMutation(
    `/api/v1/accounts/${accountId}/subscriptions`,
    manageSubscription,
    {
      onSuccess: () => {
        setSnackBarMessage('Subscription created');
        navigate(`/accounts/${accountId}/subscriptions`);
      },
      onError: () => {
        setSnackBarMessage('Something went wrong, try again');
        setStatus('ready');
      },
    }
  );

  useEffect(() => {
    setFormValid(
      !(
        title &&
        amount &&
        transactionDay &&
        expenseOrIncome !== undefined &&
        period &&
        dayjs(startDate).isValid() &&
        (!endDate || dayjs(endDate).isValid())
      )
    );
  }, [
    title,
    amount,
    startDate,
    endDate,
    expenseOrIncome,
    period,
    transactionDay,
    description,
  ]);

  function triggerSubscriptionCreation() {
    setStatus('loading');
    const data: CreateSubscriptionDto = {
      title: title.trim(),
      description: description.trim(),
      amount: +amount,
      start_date: dayjs(startDate).utc().unix(),
      end_date: endDate ? dayjs(endDate).utc().unix() : undefined,
      expense_or_income: expenseOrIncome,
      period,
      transaction_day: +transactionDay,
    };
    trigger(data);
  }

  return (
    <Box
      sx={{
        marginTop: '8px',
        marginBottom: '8px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <LoadingOverlay status={status}>
        <Card sx={{ width: '400px', maxWidth: '90vw' }}>
          <CardHeader title="Create subscription" />
          <CardContent>
            <FormControl fullWidth margin="normal">
              <TextField
                autoFocus
                required
                label="Title"
                value={title}
                onChange={({ target }) => setTitle(target.value)}
              />
            </FormControl>

            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Typography>Income</Typography>
              <FormControlLabel
                control={<Switch sx={{ m: 1 }} />}
                label=""
                checked={expenseOrIncome === 'expense'}
                onChange={({ target }) =>
                  setExpenseOrIncome(
                    (target as HTMLInputElement).checked ? 'expense' : 'income'
                  )
                }
              />
              <Typography>Expense</Typography>
            </Stack>

            <FormControl fullWidth margin="normal">
              <TextField
                label="Amount"
                type="number"
                required
                value={amount}
                onChange={({ target }) => setAmount(target.value)}
              />
            </FormControl>

            <Box sx={{ display: 'flex', gap: '8px' }}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Start Date"
                  type="date"
                  required
                  value={startDate}
                  onChange={({ target }) => setStartDate(target.value)}
                />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={({ target }) => setEndDate(target.value)}
                />
              </FormControl>
            </Box>

            <PeriodSelector
              initialPeriod={period}
              initialTransactionDay={transactionDay}
              onPeriodChange={setPeriod}
              onTransactionDayChange={setTransactionDay}
            />

            <FormControl fullWidth margin="normal">
              <TextField
                label="Description"
                multiline
                rows={2}
                value={description}
                onChange={({ target }) => setDescription(target.value)}
              />
            </FormControl>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={() => navigate(`/accounts/${accountId}/subscriptions`)}
            >
              Cancel
            </Button>

            <Button
              variant="outlined"
              disabled={formValid}
              onClick={triggerSubscriptionCreation}
            >
              Create
            </Button>
          </CardActions>
        </Card>
      </LoadingOverlay>

      <Snackbar
        open={snackBarMessage !== ''}
        autoHideDuration={3000}
        onClose={() => setSnackBarMessage('')}
        message={snackBarMessage}
      />
    </Box>
  );
}
