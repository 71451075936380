import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';

const stickyFabMargin = '8px';

export const stickyBottomLeftFab: SystemStyleObject = {
  position: 'fixed',
  bottom: stickyFabMargin,
  left: stickyFabMargin,
};

export const stickyBottomRightFab: SystemStyleObject = {
  position: 'fixed',
  bottom: stickyFabMargin,
  right: stickyFabMargin,
};
