import React, { JSX, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import RepeatIcon from '@mui/icons-material/Repeat';
import { stickyBottomRightFab } from '../../../../../common/styles/styles';

interface AddActionsProps {
  accountId: string;
}

export function AddActions({ accountId }: AddActionsProps): JSX.Element {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleAddTransaction = () => {
    navigate(`/accounts/${accountId}/createTransaction`);
  };

  const handleAddSubscription = () => {
    navigate(`/accounts/${accountId}/subscriptions`);
  };

  return (
    <SpeedDial
      ariaLabel="Add actions"
      sx={stickyBottomRightFab}
      icon={<SpeedDialIcon />}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <SpeedDialAction
        icon={<CompareArrowsIcon />}
        tooltipTitle="Transaction"
        tooltipOpen
        onClick={handleAddTransaction}
      />
      <SpeedDialAction
        icon={<RepeatIcon />}
        tooltipTitle="Subscription"
        tooltipOpen
        onClick={handleAddSubscription}
      />
    </SpeedDial>
  );
}
