import React, { JSX, useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { Period } from '../../../../../../common/dtos/subscriptions';
import { TransactionDayInput } from './TransactionDayInput';

type PeriodSelectorProps = {
  initialPeriod: Period;
  initialTransactionDay: number;
  onPeriodChange: (period: Period) => void;
  onTransactionDayChange: (transactionDay: number) => void;
};

export function PeriodSelector({
  initialPeriod,
  initialTransactionDay,
  onPeriodChange,
  onTransactionDayChange,
}: PeriodSelectorProps): JSX.Element {
  const [period, setPeriod] = useState(initialPeriod);
  const [transactionDay, setTransactionDay] = useState(initialTransactionDay);

  useEffect(() => {
    onPeriodChange(period);
  }, [period]);

  useEffect(() => {
    onTransactionDayChange(transactionDay);
  }, [transactionDay]);

  return (
    <Stack direction="row" spacing={1}>
      <FormControl fullWidth margin="normal">
        <InputLabel id="period-label">Period</InputLabel>
        <Select
          value={period}
          onChange={({ target }) => setPeriod(target.value as Period)}
          label="Period"
        >
          <MenuItem value={Period.monthly}>Monthly</MenuItem>
        </Select>
      </FormControl>

      <TransactionDayInput
        transactionDay={transactionDay}
        onTransactionDayChange={setTransactionDay}
      />
    </Stack>
  );
}
