import React, { JSX, useContext, useState } from 'react';
import useSWR from 'swr';
import dayjs, { Dayjs } from 'dayjs';
import { Box, Fab, Stack } from '@mui/material';
import FunctionIcon from '@mui/icons-material/Functions';
import { LoadingOverlay } from '../../../../../common/components/loading-overlay/LoadingOverlay';
import { TransactionsDto } from '../../../../../common/dtos/transactions';
import { TransactionsTable } from './TransactionsTable';
import { DateRangeSelector } from './DateRangeSelector';
import { AccountContext } from '../../account-context';
import { AddActions } from './AddActions';
import { NoDataMessage } from '../../../../../common/components/no-data-message/NoDataMessage';
import { stickyBottomLeftFab } from '../../../../../common/styles/styles';

const getTransactions = async ([url, start, end]: [
  string,
  number,
  number,
]): Promise<TransactionsDto> => {
  const res = await fetch(
    `${url}?${new URLSearchParams({
      start_date: start.toString(),
      end_date: end.toString(),
    })}`
  );
  if (res.ok) {
    return res.json();
  }

  throw new Error('Failed to fetch transactions');
};

export function TransactionsPage(): JSX.Element {
  const { id: accountId } = useContext(AccountContext);
  const [startDate, setStartDate] = useState(dayjs().utc().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().utc());
  const [total, setTotal] = useState(0);

  const { data, isLoading } = useSWR(
    [
      `/api/v1/accounts/${accountId}/transactions`,
      startDate.unix(),
      endDate.unix(),
    ],
    getTransactions,
    {
      onSuccess: (res: TransactionsDto) => {
        setTotal(
          res.transactions.reduce((acc, transaction) => {
            return (
              acc +
              (transaction.expense_or_income === 'expense'
                ? -transaction.amount
                : transaction.amount)
            );
          }, 0)
        );
      },
    }
  );

  function onDateRangeChange(start: Dayjs, end: Dayjs): void {
    setStartDate(start);
    setEndDate(end);
  }

  return (
    <LoadingOverlay status={isLoading ? 'loading' : 'ready'}>
      {!isLoading && data && (
        <Stack spacing={2}>
          <DateRangeSelector
            startDate={startDate}
            endDate={endDate}
            onDateRangeChange={onDateRangeChange}
          />
          {data.transactions.length > 0 ? (
            <Box sx={{ marginBottom: '42px' }}>
              <TransactionsTable transactions={data.transactions} />
            </Box>
          ) : (
            <NoDataMessage>
              No transactions yet. Add one to get started!
            </NoDataMessage>
          )}
          <Box />
        </Stack>
      )}

      <AddActions accountId={accountId} />

      <Fab
        variant="extended"
        color={total < 0 ? 'error' : 'success'}
        sx={stickyBottomLeftFab}
      >
        <FunctionIcon sx={{ mr: 1 }} />
        {total}
      </Fab>
    </LoadingOverlay>
  );
}
