import React, { JSX, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  FormControl,
  Snackbar,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FullCenter } from '../../common/components/full-center/FullCenter';
import {
  LoadingOverlay,
  OverlayStatus,
} from '../../common/components/loading-overlay/LoadingOverlay';

export function Register(): JSX.Element {
  const navigate = useNavigate();
  const [status, setStatus] = useState<OverlayStatus>('ready');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [snackBarMessage, setSnackBarMessage] = useState('');

  function register() {
    setStatus('loading');
    fetch('/api/v1/users', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
    })
      .then((res) => {
        if (res.ok) {
          navigate('/auth/login');
          return;
        }

        setSnackBarMessage('Something went wrong');
      })
      .finally(() => setStatus('ready'));
  }

  return (
    <FullCenter>
      <LoadingOverlay status={status}>
        <Card style={{ maxWidth: '380px' }}>
          <CardHeader title="Register" />
          <CardMedia
            component="img"
            height="180"
            image={`${process.env.PUBLIC_URL}/images/arnak.png`}
            alt="green iguana"
          />
          <CardContent>
            <FormControl fullWidth margin="dense">
              <TextField
                id="email"
                label="Email"
                onChange={({ target }) => setEmail(target.value)}
              />
            </FormControl>
            <FormControl fullWidth style={{ marginTop: '12px' }}>
              <TextField
                id="password"
                label="Password"
                type="password"
                onChange={({ target }) => setPassword(target.value)}
              />
            </FormControl>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button size="small" variant="outlined" onClick={register}>
              Register
            </Button>
            <Button size="small" onClick={() => navigate('/auth/login')}>
              Login
            </Button>
          </CardActions>
        </Card>
      </LoadingOverlay>

      <Snackbar
        open={snackBarMessage !== ''}
        autoHideDuration={3000}
        onClose={() => setSnackBarMessage('')}
        message={snackBarMessage}
      />
    </FullCenter>
  );
}
