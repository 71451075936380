import React, { JSX, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { AccountInfo } from './account-info/AccountInfo';
import { TransactionsPage } from './transaction/overview/TransactionsPage';
import { CreateTransaction } from './transaction/CreateTransaction';
import { AccountDto } from '../../../common/dtos/accounts';
import {
  LoadingOverlay,
  OverlayStatus,
} from '../../../common/components/loading-overlay/LoadingOverlay';
import { AccountContext } from './account-context';
import { SubscriptionsPage } from './subscription/SubscriptionsPage';
import { ManageSubscription } from './subscription/ManageSubscription';

const getAccount = async (url: string): Promise<AccountDto> => {
  const res = await fetch(url);

  if (res.ok) {
    return res.json();
  }

  throw new Error('Failed to fetch Account');
};

export function AccountContextRoot(): JSX.Element {
  const navigate = useNavigate();
  const [status, setStatus] = useState<OverlayStatus>('loading');
  const { accountId } = useParams();

  if (!accountId) {
    navigate('/accounts/select');
    return <div />;
  }

  localStorage.setItem('accountId', accountId);

  const { data } = useSWR([`/api/v1/accounts/${accountId}`], getAccount, {
    onSuccess: () => {
      setStatus('ready');
    },
    onError: () => {
      setStatus('error');
    },
  });

  return (
    <LoadingOverlay status={status}>
      {data && (
        <AccountContext.Provider value={data}>
          <Routes>
            <Route path="info" element={<AccountInfo />} />
            <Route path="transactions" element={<TransactionsPage />} />
            <Route path="createTransaction" element={<CreateTransaction />} />
            <Route path="subscriptions" element={<SubscriptionsPage />} />
            <Route path="createSubscription" element={<ManageSubscription />} />
          </Routes>
        </AccountContext.Provider>
      )}
    </LoadingOverlay>
  );
}
