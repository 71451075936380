import React, { JSX, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  Card,
  CardContent,
  FormControl,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

type DateRangeSelectorProps = {
  startDate: Dayjs;
  endDate: Dayjs;
  onDateRangeChange: (startDate: Dayjs, endDate: Dayjs) => void;
};

export function DateRangeSelector({
  onDateRangeChange,
  ...props
}: DateRangeSelectorProps): JSX.Element {
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);

  return (
    <Card>
      <CardContent
        sx={{
          padding: '16px',
          paddingBottom: '16px !important',
        }}
      >
        <Stack direction="row" spacing={1}>
          <FormControl margin="normal" fullWidth>
            <TextField
              label="Start Date"
              type="date"
              size="small"
              value={startDate.format('YYYY-MM-DD')}
              onChange={({ target }) => setStartDate(dayjs(target.value))}
            />
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <TextField
              label="End Date"
              type="date"
              size="small"
              value={endDate.format('YYYY-MM-DD')}
              onChange={({ target }) => setEndDate(dayjs(target.value))}
            />
          </FormControl>

          <IconButton
            onClick={() => onDateRangeChange(startDate, endDate)}
            color="primary"
          >
            <SearchIcon />
          </IconButton>
        </Stack>
      </CardContent>
    </Card>
  );
}
