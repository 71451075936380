import React, { JSX, useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dayjs from 'dayjs';
import { TransactionDto } from '../../../../../common/dtos/transactions';

type TransactionsTableProps = {
  transactions: TransactionDto[];
};

function TransactionRow({
  transaction,
}: {
  transaction: TransactionDto;
}): JSX.Element {
  const [open, setOpen] = useState(false);
  const madeAt = dayjs.unix(transaction.made_at);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div>{madeAt.format('DD/MM/YYYY')}</div>
            <div>{madeAt.format('HH:mm')}</div>
          </Box>
        </TableCell>
        <TableCell>{transaction.title}</TableCell>
        <TableCell
          align="right"
          sx={{
            color:
              transaction.expense_or_income === 'expense' ? 'red' : 'green',
          }}
        >
          {transaction.expense_or_income === 'expense' ? '-' : ''}
          {transaction.amount}
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {transaction.description}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export function TransactionsTable({
  transactions,
}: TransactionsTableProps): JSX.Element {
  return (
    <Paper sx={{ borderRadius: '4px', overflow: 'hidden' }}>
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell size="small">Date</TableCell>
              <TableCell>Title</TableCell>
              <TableCell align="right" size="small">
                Amount
              </TableCell>
              <TableCell align="right" size="small">
                Details
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TransactionRow transaction={transaction} key={transaction.id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
