import React, { JSX, PropsWithChildren, useState } from 'react';
import {
  AppBar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

function AccountToolbar(): JSX.Element {
  const accountId = localStorage.getItem('accountId');
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const doMenuNav = (mavTo: string) => {
    navigate(mavTo);
    handleClose();
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Arnak
        </Typography>

        <IconButton size="small" onClick={handleClick}>
          <MenuIcon fontSize="small" sx={{ color: 'white' }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={() => doMenuNav(`/accounts/${accountId}/info`)}>
            My account
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export function AccountLayout({ children }: PropsWithChildren): JSX.Element {
  return (
    <Stack sx={{ height: '100vh' }}>
      <AccountToolbar />
      <Stack
        sx={{
          padding: '8px',
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
}
