import React, { JSX, PropsWithChildren } from 'react';
import { Paper } from '@mui/material';

export function NoDataMessage({ children }: PropsWithChildren): JSX.Element {
  return (
    <Paper elevation={1} sx={{ padding: '8px' }}>
      <p>{children}</p>
    </Paper>
  );
}
