import React, { PropsWithChildren } from 'react';
import { Box, CircularProgress } from '@mui/material';

export type OverlayStatus = 'loading' | 'ready' | 'saving' | 'error';

type LoadingOverlayProps = PropsWithChildren & {
  status: OverlayStatus;
};

export function LoadingOverlay({ children, status }: LoadingOverlayProps) {
  return (
    <Box
      sx={
        status === 'loading'
          ? { position: 'relative', pointerEvents: 'none', height: '100%' }
          : {}
      }
    >
      {children}
      {status === 'loading' && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(204, 204, 204, 0.5)',
            zIndex: 999,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {status === 'error' && <div>Failed to load data</div>}
    </Box>
  );
}
