import React, { JSX, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import { CreateTransactionDto } from '../../../../common/dtos/transactions';
import {
  LoadingOverlay,
  OverlayStatus,
} from '../../../../common/components/loading-overlay/LoadingOverlay';
import { restFetch } from '../../../../common/utils/fetch';

function createTransaction(
  url: string,
  { arg }: { arg: CreateTransactionDto }
) {
  return restFetch(url, {
    method: 'POST',
    body: JSON.stringify(arg),
  });
}

export function CreateTransaction(): JSX.Element {
  const navigate = useNavigate();
  const { accountId } = useParams<{ accountId: string }>();
  const [status, setStatus] = useState<OverlayStatus>('ready');
  const [title, setTitle] = useState('');
  const [isExpense, setIsExpense] = useState(true);
  const [amount, setAmount] = useState<string>('');
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DDThh:mm'));
  const [formValid, setFormValid] = useState(false);
  const [description, setDescription] = useState('');
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const { trigger } = useSWRMutation(
    `/api/v1/accounts/${accountId}/transactions`,
    createTransaction,
    {
      onSuccess: () => {
        setSnackBarMessage('Transaction created');
        navigate(`/accounts/${accountId}/transactions`);
      },
      onError: () => {
        setSnackBarMessage('Something went wrong, try again');
        setStatus('ready');
      },
    }
  );

  useEffect(() => {
    setFormValid(!(title && amount && date && dayjs(date).isValid()));
  }, [title, amount, date]);

  function triggerTransactionCreation() {
    setStatus('loading');
    const data: CreateTransactionDto = {
      title,
      description,
      expense_or_income: isExpense ? 'expense' : 'income',
      type: 'one_time',
      amount: +amount,
      made_at: dayjs(date).utc().unix(),
    };
    trigger(data);
  }

  return (
    <Box
      style={{
        marginTop: '8px',
        marginBottom: '8px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <LoadingOverlay status={status}>
        <Card sx={{ width: '400px', maxWidth: '90vw' }}>
          <CardHeader title="Create transaction" />
          <CardContent>
            <FormControl fullWidth margin="normal">
              <TextField
                autoFocus
                required
                label="Title"
                value={title}
                onChange={({ target }) => setTitle(target.value)}
              />
            </FormControl>

            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Typography>Income</Typography>
              <FormControlLabel
                control={<Switch sx={{ m: 1 }} />}
                label=""
                checked={isExpense}
                onChange={({ target }) =>
                  setIsExpense((target as HTMLInputElement).checked)
                }
              />
              <Typography>Expense</Typography>
            </Stack>

            <FormControl fullWidth margin="normal">
              <TextField
                label="Amount"
                type="number"
                required
                value={amount}
                onChange={({ target }) => setAmount(target.value)}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                label="Date"
                type="date"
                required
                value={date}
                onChange={({ target }) => setDate(target.value)}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                label="Description"
                multiline
                rows={2}
                value={description}
                onChange={({ target }) => setDescription(target.value)}
              />
            </FormControl>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={() => navigate(`/accounts/${accountId}/transactions`)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              disabled={formValid}
              onClick={triggerTransactionCreation}
            >
              Create
            </Button>
          </CardActions>
        </Card>
      </LoadingOverlay>

      <Snackbar
        open={snackBarMessage !== ''}
        autoHideDuration={3000}
        onClose={() => setSnackBarMessage('')}
        message={snackBarMessage}
      />
    </Box>
  );
}
