import React, { JSX } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

type TransactionDayInputProps = {
  transactionDay: number;
  onTransactionDayChange: (transactionDay: number) => void;
};

export function TransactionDayInput({
  transactionDay,
  onTransactionDayChange,
}: TransactionDayInputProps): JSX.Element {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel>Day of month</InputLabel>
      <Select
        label="Day of month"
        value={transactionDay}
        onChange={({ target }) => onTransactionDayChange(+target.value)}
      >
        {Array.from({ length: 31 }, (_, i) => (
          <MenuItem key={i + 1} value={i + 1}>
            {i + 1}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
