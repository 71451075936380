import React, { JSX, useContext, useState } from 'react';
import useSWR from 'swr';
import { Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../account-context';
import {
  LoadingOverlay,
  OverlayStatus,
} from '../../../../common/components/loading-overlay/LoadingOverlay';
import { SubscriptionsDto } from '../../../../common/dtos/subscriptions';
import { restFetch } from '../../../../common/utils/fetch';
import { NoDataMessage } from '../../../../common/components/no-data-message/NoDataMessage';
import { SubscriptionsList } from './SubscriptionsList';
import {
  stickyBottomLeftFab,
  stickyBottomRightFab,
} from '../../../../common/styles/styles';

const getSubscriptions = async ([url]: [
  string,
  number,
  number,
]): Promise<SubscriptionsDto> => {
  return restFetch(url);
};

export function SubscriptionsPage(): JSX.Element {
  const navigate = useNavigate();
  const { id: accountId } = useContext(AccountContext);
  const [status, setStatus] = useState<OverlayStatus>('loading');

  const { data, isLoading } = useSWR(
    [`/api/v1/accounts/${accountId}/subscriptions`],
    getSubscriptions,
    {
      onSuccess: () => {
        setStatus('ready');
      },
      onError: () => {
        setStatus('error');
      },
    }
  );

  return (
    <LoadingOverlay status={status}>
      {!isLoading && data && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          {data.subscriptions.length > 0 ? (
            <Box sx={{ marginBottom: '42px' }}>
              <SubscriptionsList subscriptions={data.subscriptions} />
            </Box>
          ) : (
            <NoDataMessage>
              No subscriptions yet. Add one to get started!
            </NoDataMessage>
          )}
          <Box />
        </Box>
      )}

      <Fab
        color="primary"
        sx={stickyBottomRightFab}
        onClick={() => navigate(`/accounts/${accountId}/createSubscription`)}
      >
        <AddIcon />
      </Fab>

      <Fab
        variant="extended"
        color="success"
        sx={stickyBottomLeftFab}
        onClick={() => navigate(`/accounts/${accountId}/transactions`)}
      >
        Transactions
      </Fab>
    </LoadingOverlay>
  );
}
