import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

export function FullCenter({ children }: PropsWithChildren) {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </Box>
  );
}
