import React, { JSX } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AccountLayout } from './components/AccountLayout';
import { AccountSelect } from './account-select/AccountSelect';
import { CreateAccount } from './create-account/CreateAccount';
import { AccountContextRoot } from './account-context/AccountContextRoot';

export function AccountRoot(): JSX.Element {
  return (
    <AccountLayout>
      <Routes>
        <Route path="create" element={<CreateAccount />} />
        <Route path="select" element={<AccountSelect />} />
        <Route path=":accountId/*" element={<AccountContextRoot />} />
      </Routes>
    </AccountLayout>
  );
}
